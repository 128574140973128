import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "p-4" }
const _hoisted_3 = { class: "d-flex flex-wrap justify-content-center" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { class: "fs-2 fw-bolder" }
const _hoisted_6 = { class: "fw-bold fs-6 text-gray-400" }
const _hoisted_7 = { class: "card-header border-0 pt-2" }
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "d-flex align-items-center w-300px position-relative my-1 mx-1 col" }
const _hoisted_10 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_11 = { class: "d-flex align-items-center w-300px position-relative my-1 mx-1" }
const _hoisted_12 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_13 = { class: "d-flex align-items-center position-relative my-1 mx-1" }
const _hoisted_14 = { class: "d-flex align-items-center position-relative my-1 mx-1" }
const _hoisted_15 = { class: "card-title d-flex w-100" }
const _hoisted_16 = { class: "my-1 mx-1" }
const _hoisted_17 = { class: "my-1 mx-1" }
const _hoisted_18 = {
  key: 0,
  class: "my-1 mx-1"
}
const _hoisted_19 = { class: "my-1 mx-1" }
const _hoisted_20 = { class: "card-body pt-0" }
const _hoisted_21 = { class: "dataTables_wrapper dt-bootstrap4 no-footer" }
const _hoisted_22 = { class: "table-responsive" }
const _hoisted_23 = {
  class: "table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer",
  role: "grid"
}
const _hoisted_24 = { class: "odd" }
const _hoisted_25 = { class: "align-text-top" }
const _hoisted_26 = { class: "align-text-top text-end" }
const _hoisted_27 = { class: "align-text-top text-end" }
const _hoisted_28 = { class: "align-text-top text-end" }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" }
const _hoisted_31 = { class: "col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Currency = _resolveComponent("Currency")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_DateTime = _resolveComponent("DateTime")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_OrderGateway = _resolveComponent("OrderGateway")!
  const _component_OrderPaymentMethod = _resolveComponent("OrderPaymentMethod")!
  const _component_OrderStatus = _resolveComponent("OrderStatus")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totals, (value, key) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3",
            key: key
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Currency, {
                  code: key,
                  amount: value
                }, null, 8, ["code", "amount"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, _toDisplayString(key), 1)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid ps-15",
            placeholder: "E-mail"
          }, null, 544), [
            [_vModelText, _ctx.email]
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("span", _hoisted_12, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.number) = $event)),
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid ps-15",
            placeholder: "Número da Venda"
          }, null, 544), [
            [_vModelText, _ctx.number]
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.startDate,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.startDate) = $event)),
            name: "startDate",
            format: "DD/MM/YYYY",
            valueFormat: "YYYY-MM-DD",
            onChange: _ctx.load,
            class: "w-300px"
          }, null, 8, ["modelValue", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.endDate,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.endDate) = $event)),
            name: "endDate",
            format: "DD/MM/YYYY",
            valueFormat: "YYYY-MM-DD",
            class: "w-300px",
            onChange: _ctx.load
          }, null, 8, ["modelValue", "onChange"])
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_el_select, {
            class: "form-select-solid w-300px",
            placeholder: "Situação",
            modelValue: _ctx.status,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.status) = $event)),
            onChange: _ctx.load,
            clearable: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "Paga",
                value: "1"
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("Paga")
                ])),
                _: 1
              }),
              _createVNode(_component_el_option, {
                label: "Aguardando Pagamento",
                value: "0"
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode("Aguardando Pagamento")
                ])),
                _: 1
              }),
              _createVNode(_component_el_option, {
                label: "Cancelada",
                value: "2"
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode("Cancelada")
                ])),
                _: 1
              }),
              _createVNode(_component_el_option, {
                label: "Devolvida",
                value: "3"
              }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("Devolvida")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_el_select, {
            class: "form-select-solid w-300px",
            placeholder: "Gateway",
            modelValue: _ctx.gateway,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.gateway) = $event)),
            onChange: _ctx.load,
            clearable: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gateways, (gateway) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: gateway.id,
                  label: gateway.title,
                  value: gateway.id
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ]),
        (_ctx.gateway)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createVNode(_component_el_select, {
                class: "form-select-solid w-300px",
                placeholder: "Forma de Pagamento",
                modelValue: _ctx.payment_method,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payment_method) = $event)),
                onChange: _ctx.load,
                clearable: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payment_methods, (payment_method) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: payment_method.payment_method,
                      label: payment_method.payment_method,
                      value: payment_method.payment_method
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "onChange"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_el_select, {
            class: "form-select-solid w-300px",
            placeholder: "Todas as Moedas",
            modelValue: _ctx.currency,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.currency) = $event)),
            onChange: _ctx.load,
            clearable: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currencies, (currency) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: currency.id,
                  label: currency.title,
                  value: currency.id
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("table", _hoisted_23, [
            _cache[15] || (_cache[15] = _createElementVNode("thead", null, [
              _createElementVNode("tr", {
                class: "text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0",
                role: "row"
              }, [
                _createElementVNode("th", null, "ID"),
                _createElementVNode("th", null, "Número"),
                _createElementVNode("th", null, "Data da Venda"),
                _createElementVNode("th", null, "Cliente"),
                _createElementVNode("th", null, "Gateway"),
                _createElementVNode("th", null, "Forma de Pagamento"),
                _createElementVNode("th", null, "Situação"),
                _createElementVNode("th", { class: "text-right" }, "Subtotal"),
                _createElementVNode("th", { class: "text-right" }, "Desconto"),
                _createElementVNode("th", { class: "text-right" }, "Total")
              ])
            ], -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (order) => {
              return (_openBlock(), _createElementBlock("tbody", {
                class: "fw-bold text-gray-600",
                key: order.id
              }, [
                _createElementVNode("tr", _hoisted_24, [
                  _createElementVNode("td", null, _toDisplayString(order.id), 1),
                  _createElementVNode("td", null, _toDisplayString(order.order_number), 1),
                  _createElementVNode("td", null, [
                    _createVNode(_component_DateTime, {
                      "date-time": order.created_at
                    }, null, 8, ["date-time"])
                  ]),
                  _createElementVNode("td", null, [
                    (order.user)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: `/apps/customers/customer-details/${order.user.id}`
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(order.user.email), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_OrderGateway, {
                      gateway: order.gateway || (order.payments ? order.payments[0].gateway : null)
                    }, null, 8, ["gateway"])
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_OrderPaymentMethod, {
                      "payment-method": order.payment_method || (order.payments ? order.payments[0].payment_method : null)
                    }, null, 8, ["payment-method"])
                  ]),
                  _createElementVNode("td", _hoisted_25, [
                    _createVNode(_component_OrderStatus, {
                      status: order.status
                    }, null, 8, ["status"])
                  ]),
                  _createElementVNode("td", _hoisted_26, [
                    _createVNode(_component_Currency, {
                      code: order.currency.code,
                      amount: order.subtotal
                    }, null, 8, ["code", "amount"])
                  ]),
                  _createElementVNode("td", _hoisted_27, [
                    _createVNode(_component_Currency, {
                      code: order.currency.code,
                      amount: order.discount
                    }, null, 8, ["code", "amount"])
                  ]),
                  _createElementVNode("td", _hoisted_28, [
                    _createVNode(_component_Currency, {
                      code: order.currency.code,
                      amount: order.total
                    }, null, 8, ["code", "amount"])
                  ])
                ])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Total", -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.pagination.total), 1)
          ]),
          _createElementVNode("div", _hoisted_31, [
            _createVNode(_component_el_pagination, {
              "current-page": _ctx.pagination.page,
              "onUpdate:currentPage": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.pagination.page) = $event)),
              onCurrentChange: _ctx.load,
              "page-size": 10,
              layout: "prev, pager, next",
              total: _ctx.pagination.total,
              "hide-on-single-page": true,
              background: ""
            }, null, 8, ["current-page", "onCurrentChange", "total"])
          ])
        ])
      ])
    ])
  ]))
}