import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 5 }
const _hoisted_7 = { key: 6 }
const _hoisted_8 = { key: 7 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.paymentMethod)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "-"))
      : (_ctx.paymentMethod.indexOf('paypalsubscription') > -1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Assinatura"))
        : (_ctx.paymentMethod.indexOf('credit') > -1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Cartão de Crédito"))
          : (_ctx.paymentMethod.indexOf('paypalexpress') > -1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Express"))
            : (_ctx.paymentMethod.indexOf('ticket') > -1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Boleto"))
              : (_ctx.paymentMethod.indexOf('pix') > -1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Pix"))
                : (_ctx.paymentMethod.indexOf('transfer') > -1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Transferência"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.paymentMethod), 1))
  ]))
}