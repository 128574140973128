
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    gateway: {
      default: '',
      type: String
    },
  },
});
