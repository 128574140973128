import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.gateway)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "-"))
      : (_ctx.gateway.indexOf('paypal') > -1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "PayPal"))
        : (_ctx.gateway.indexOf('pagarme') > -1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Pagar.me"))
          : (_ctx.gateway.indexOf('picpay') > -1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "PicPay"))
            : (_ctx.gateway.indexOf('bank') > -1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Banco"))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.gateway), 1))
  ]))
}