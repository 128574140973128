
import {defineComponent, onMounted, reactive, ref, watch} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import DateTime from "@/components/common/DateTime.vue";
import Currency from "@/components/common/Currency.vue";
import OrderGateway from "@/components/common/OrderGateway.vue";
import OrderPaymentMethod from "@/components/common/OrderPaymentMethod.vue";
import OrderStatus from "@/components/orders/OrderStatus.vue";

export default defineComponent({
  name: "orders-listing",
  components: {
    OrderStatus,
    OrderGateway,
    OrderPaymentMethod,
    Currency,
    DateTime
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Vendas", []);
      load()
    });

    let searching;
    const searchItems = (): void => {
      clearTimeout(searching)
      searching = setTimeout(() => load(), 700)
    };

    const tableData = ref([]);
    const totals = ref([]);

    // Filter
    const email = ref<string>("");
    const number = ref<string>("");

    const today = new Date().toJSON().slice(0, 10)
    const startDate = ref<string>(today);
    const endDate = ref<string>(today);
    const status = ref<string>("1");
    const gateway = ref<string>("");
    const payment_method = ref<string>("");
    const currency = ref("");

    // Filter List
    const currencies = ref([]);
    const gateways = ref([
      {
        id: 'pagarme',
        title: 'Pagar.me'
      },
      {
        id: 'paypal',
        title: 'PayPal'
      },
      {
        id: 'picpay',
        title: 'PicPay'
      }
    ]);
    const payment_methods = ref([]);

    const load = (): void => {
      ApiService.setHeader();
      ApiService.get(`v1/backend/orders?page=${pagination.page}&start_date=${startDate.value}&end_date=${endDate.value}&email=${email.value}&status=${status.value}&number=${number.value}&currency=${currency.value}&gateway=${gateway.value}&payment_method=${payment_method.value}`)
          .then(({data}) => {
            tableData.value = data.orders.data;
            totals.value = data.orders_totals;
            currencies.value = data.currencies;
            payment_methods.value = data.payment_methods;
            pagination.page = data.orders.current_page;
            pagination.total = data.orders.total;
          })
          .catch(({response}) => {
            console.log(response);
          });
    };

    const pagination = reactive({
      page: 1,
      total: 0,
    })

    return {
      totals,
      tableData,
      // Filter
      email,
      gateway,
      payment_method,
      number,
      gateways,
      payment_methods,
      status,
      load,
      pagination,
      searchItems,
      startDate,
      endDate,
      currencies,
      currency,
    };
  },
});
